<template>
  <div class="hearing_warp">
    <div class="main_top">
      <div>
        <el-select
          v-model="procuratorateid"
          placeholder="请选择检察院名称"
          style="width: 220px"
        >
          <el-option
            v-for="item in proList"
            :key="item.id"
            :value="item.id"
            :label="item.procuratoratename"
            :disabled="disabled"

          ></el-option>
        </el-select>
      <el-input
        v-model="selectTxt"
        placeholder="请输入听证员搜索"
        style="width: 300px"
        class="select-actName"
      ></el-input>
      <el-button
        slot="reference"
        class="style_button style_button_Sel"
        icon="el-icon-search"
        @click="activitySelect"
        >搜索</el-button
      >
      </div>

         <!-- <el-button
            class="style_button"
            style="background: white; color: #044bfe; border: 1px solid #044bfe"
            type="text"
            @click="handleExport()"
            >导出</el-button
          > -->

    </div>
  
    <el-table
      :data="tableData"
      style="width: 100%"
      height="590"
    >
      <el-table-column label="序号" width="100" type="index"> </el-table-column>

      <el-table-column prop="username" label="姓名"> </el-table-column>
      <el-table-column prop="sex" label="性别">
        <template slot-scope="scope">
          <p>{{ scope.row.sex == 0 ? '女' : '男'}}</p>
        </template>
      </el-table-column>
      <el-table-column prop="procuratorate" label="所属检察院"> 
        <template slot-scope="scope">
              <p>{{ scope.row.procuratorate.procuratoratename}}</p>
            </template>
      </el-table-column>
      <el-table-column prop="professional" label="专业领域"> </el-table-column>
      <el-table-column prop="phone" label="手机号"> </el-table-column>
      <el-table-column prop="evaluation" label="满意度评价" sortable>
      </el-table-column>
      <el-table-column prop="participate" label="参与率(%)" sortable>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { Message } from "element-ui";
// getQueryStatsUserSearch
import { getQueryStatsUser, } from "@/api/hearing";
import {
  getProInfoList
} from "@/api/system";
import { downloadFile } from "@/util/formtime";
import { BASE_URL } from "@/config";
export default {
  data() {
    return {
      value: [],
      perList: [],
      tableData: [],
      total: 0, //总条数
      page: 1, //页数
      pageSize: 10, //每页限制
      currentPage: 1, //当前页数
      options_name: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "待确认",
        },
        {
          value: "2",
          label: "已确认",
        },
        {
          value: "3",
          label: "已完成",
        },
      ],
      selectTxt: "", //搜索当前项
      value_time: "", //搜索时间
      value_name: "", //搜索状态
      hearInfo: [{}], //听证详情
      expertList: [], //专家列表
      proList:[],
      procuratorateid: null,
      disabled:false,
      dialogFormVisible: false,
      formLabelWidth: "75px",
    };
  },
  mounted() {
    this.getproList();
    this.typeid = JSON.parse(
      window.localStorage.getItem("tltz_userinfo")
    ).types.id;
    if (this.typeid == 6) {
      this.procuratorateid = -1;
    } else {
      this.procuratorateid = JSON.parse(
        window.localStorage.getItem("tltz_userinfo")
      ).procuratorate.id;
      this.disabled = true
    }
    this._getQueryStatsUser();
    this.perList = JSON.parse(window.localStorage.getItem("permissionidList"));
    console.log(this.perList);
  },
  methods: {
   //获取列表
    getproList() {
      getProInfoList(this.page, this.pageSize).then((data) => {
        this.proList = data.data.list;
        this.proList.unshift({
          id: -1,
          procuratoratename: '全部'
        })
      });
    },
    //获取活动列表
    _getQueryStatsUser() {
      const hearingOfficerBo = {
        adminid : JSON.parse(window.localStorage.getItem("tltz_userinfo")).id,
        procuratorateid: this.procuratorateid,
        page: this.page,
        pageSize: this.pageSize,
      }
       if( this.selectTxt !== '' ){
        hearingOfficerBo.username = this.selectTxt
      }
      getQueryStatsUser(hearingOfficerBo).then((data) => {
        if (data.data == null) {
          this.page = 1;
           this.tableData = [];
          Message.error(data.msg);
          return;
        } else {
          this.tableData = data.data.list;
          this.total = data.data.total;
          this.currentPage = data.data.pageNum;
        }
      });
    },
    //搜索
    activitySelect() {
      this._getQueryStatsUser();
      // let res = this.perList.filter((val) => val == 24);
      // console.log(res)
      // if(!res.length){
      //   Message.error('您没有这个权限');
        
      // }else{
      // let name = this.selectTxt;
      // getQueryStatsUserSearch(name).then((data) => {
      //   if (data.data == null) {
      //     Message.warning("查询内容不存在！");
      //   } else {
      //     this.tableData = data.data;
      //   }
      // });
      // }
    },
    closeDialog() {
      this.dialogFormVisible = false;
    },
    //导出
    handleExport() {
      let res = this.perList.filter((val) => val == 77);
      console.log(res)
      if(!res.length){
        Message.error('您没有这个权限');
        
      }else{
      downloadFile(BASE_URL + `/QueryStats/exportStatsExpert`);
      }
    },
    //分页器
    handleCurrentChange(val) {
      this.page = val;
      // if (this.selectTxt !== null) {
      //   this.activitySelect();
      //   return;
      // }
      this._getQueryStatsUser();
    },
  },
};
</script>
<style lang='less' >
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.hearing_warp {
  box-sizing: border-box;
  position: relative;
  width: auto;
  height: 92%;
  background: #fff;
  margin-top: 16px;
  padding: 20px 13px 54px 13px;
  .main_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .select-actName {
      margin-right: 10px;
    }
    .el-select {
      margin-right: 10px;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 400px;
    }
  }
  .style_button {
    width: 100px;
    height: 40px;
    background: #044bfe;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 300;
    color: #ffffff;
    margin-left: 10px;
  }
  .hearContent {
    margin-bottom: 30px;
    .hearTitle {
      font-size: 16px;
    }
  }

  .el-table {
    margin-top: 15px;
  }
  .el-pagination {
    text-align: center;
    margin-top: 15px;
  }
  .el-pager {
    > li {
      border: 1px solid #d2dae2;
      border-radius: 2px 2px 2px 2px;
      width: 28px !important;
      height: 28px !important;
      font-size: 12px;
      margin-right: 5px;
      min-width: 28px;
    }
    > li:last-child {
      margin-right: 0;
    }
    > li.active {
      box-sizing: border-box;
      background: #3a8fea;
      color: #fff;
      width: 28px !important;
      height: 28px !important;
    }
    li.active + li {
      border-left: 1px solid #d2dae2;
    }
  }
  .cell {
   
    text-align: center;
  }
  .el-table td, .el-table th{
  padding: 15px 0;
}
}


.el-pagination .btn-prev,
.el-pagination .btn-next {
  border: 1px solid #d2dae2;
  border-radius: 2px 2px 2px 2px;
  width: 28px !important;
  height: 28px !important;
  font-size: 12px;
  margin: 0 5px;
  min-width: 28px;
}
</style>
